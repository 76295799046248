import styled, { device } from "@styled";
import triangleArrow from "@data/images/icons/triangle-arrow.png";
export const ElearningProcessWrapper = styled.section`
  padding: 150px 0;
  background-color: #ea510f;
  background: linear-gradient(
    320deg,
    ${(props) => props.theme.colors.themeColor},
    ${(props) => props.theme.colors.themeColor}
  );
  .section-title {
    p {
      color: #fff;
    }
    h6 {
        color: #fff;
    }
    h2 {
        border-left: 3px solid #fff;
        ${device.medium} {
            border-left: 5px solid #fff;
        }
    }
  }
`;
export const ElearningProcessInner = styled.div``;
export const ElearningProcessBox = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }
`;

export const ElearningProcessBoxInner = styled.div`
  background: #ed6429;
  padding: 50px 15px 40px;
  border-radius: 4px;
  border: 2px dashed #fff;
  position: relative;
  min-height: 280px;
  &:nth-child(3) {
    grid-row: 4/5;
    ${device.medium} {
      grid-row: auto;
    }
  }
  &:last-child {
    grid-row: 3/4;
    ${device.medium} {
      grid-row: auto;
    }
  }
  &:not(:first-child)::before {
    position: absolute;
    height: 34px;
    border: dashed #fff;
    width: 100%;
    z-index: 0;
    ${device.medium} {
      content: "";
    }
  }
  &:not(:first-child)::after {
    position: absolute;
    height: 34px;
    width: 21px;
    height: 17px;
    z-index: 0;
    background-image: url(${triangleArrow});
    ${device.medium} {
      content: "";
    }
  }
  &:nth-child(2)::before {
    bottom: 100%;
    left: -50%;
    border-width: 2px 2px 0;
    ${device.medium} {
      bottom: 100%;
      left: -50%;
      border-width: 2px 2px 0;
    }
  }
  &:nth-child(2)::after {
    bottom: 100%;
    right: calc(50% - 10px);
  }
  &:nth-child(3)::before {
    bottom: 50%;
    left: 100%;
    border-width: 2px 2px 2px 0;
    width: 34px;
    height: 100%;
    ${device.medium} {
      bottom: auto;
      width: 100%;
      height: 34px;
      top: 100%;
      left: 50%;
      border-width: 0 2px 2px;
    }
  }
  &:nth-child(3)::after {
    top: 100%;
    right: calc(50% - 10px);
    transform: rotate(180deg);
    ${device.medium} {
      top: 100%;
      right: calc(50% - 10px);
      left: auto;
      transform: rotate(180deg);
    }
  }
  &:last-child::before {
    bottom: 50%;
    left: 100%;
    border-width: 2px 2px 2px 0;
    width: 34px;
    height: 100%;
  }
  &:last-child::after {
    bottom: calc(50% - 7px);
    left: 100%;
    transform: rotate(90deg);
  }
`;
export const ElearningProcessHead = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;
export const ElearningProcessNo = styled.span`
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  min-width: 80px;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background: rgb(255 255 255 / 20%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
`;
export const ElearningProcessTextwrap = styled.div``;
