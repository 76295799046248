import styled from "@styled";

export const TabsSectionWrapper = styled.section`
  padding-top: 180px;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
  background-image: url(/static/blue-wave-bg-3fff129….png);
  background-size: cover;
  background-position: left top;
  background-color: #0000;
  background: linear-gradient( 320deg, #15054f, #561783 );

  .section-title {
    h2 {
      color: #fff;
    }
  }

`;
export const TabsSectionInner = styled.div``;
export const TabsWrapper = styled.div`
  background: #ff6d39;
  border-radius: 10px;
  padding: 30px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tab-content {
    width: 100%;
    ul {
      li {
        color: #fff;
        &::before {
          background: #fff;
        }
      }
    }
  }
  p {
    font-size: 17px;
    color: #fff;
  }
`;
export const TabsBottomImgWrap = styled.div`
  margin-bottom: -120px;
  margin-top: 80px;
  text-align: center;
`;
