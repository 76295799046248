import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Text from "@ui/text";
import Image from "@ui/image";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Tabs, { Tab } from "@ui/tabs";
import List, { ListItem } from "@ui/list";

import {
  TabsSectionWrapper,
  TabsSectionInner,
  TabsWrapper,
  TabsBottomImgWrap,
} from "./style";

const TabsSection = ({ data }) => {
  return (
    <TabsSectionWrapper id={data.section}>
      <TabsSectionInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <TabsWrapper>
                <Tabs  layout={2}>
                  {data &&
                    data.items.map((el, index) => (
                      <Tab eventKey={el.title} title={el.title} key={index}>
                        {el && el.description && (
                          <Text
                            dangerouslySetInnerHTML={{ __html: el.description }}
                          ></Text>
                        )}
                        {el && el.subject && (
                          <Text
                            dangerouslySetInnerHTML={{ __html: el.subject }}
                          ></Text>
                        )}
                        <List $layout="circle">
                          {!!el.list &&
                            el.list.map((el, index) => (
                              <ListItem
                              key= {index}
                                dangerouslySetInnerHTML={{ __html: el.text }}
                              ></ListItem>
                            ))}
                        </List>
                      </Tab>
                    ))}
                </Tabs>
              </TabsWrapper>
            </Col>
            {!!data.images && (
              <Col lg={12}>
                <TabsBottomImgWrap>
                  <Image
                    src={data.images[0]?.src}
                    alt={data.images[0]?.alt || "Image"}
                  />
                </TabsBottomImgWrap>
              </Col>
            )}
          </Row>
        </Container>
      </TabsSectionInner>
    </TabsSectionWrapper>
  );
};

TabsSection.propTypes = {
  SectionTitleStyle: PropTypes.object,
};
TabsSection.defaultProps = {
  SectionTitleStyle: {
  },
};
export default TabsSection;
