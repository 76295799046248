import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Text from "@ui/text";
import Heading from "@ui/heading";

import {
  ElearningProcessWrapper,
  ElearningProcessInner,
  ElearningProcessBox,
  ElearningProcessBoxInner,
  ElearningProcessHead,
  ElearningProcessNo,
  ElearningProcessTextwrap,
} from "./style";

export default function VoiceProcess({ headingStyle, descStyle, data }) {
  return (
    <ElearningProcessWrapper id={data.section}>
      <ElearningProcessInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
                description={data.section_title?.description}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ElearningProcessBox>
                {data.items.map((data, index) => (
                  <ElearningProcessBoxInner key={index}>
                    <ElearningProcessHead>
                      <ElearningProcessNo>{data.id}</ElearningProcessNo>
                      <Heading {...headingStyle}>{data.title}</Heading>
                    </ElearningProcessHead>
                    <ElearningProcessTextwrap>
                      <Text {...descStyle}> {data.description}</Text>
                    </ElearningProcessTextwrap>
                  </ElearningProcessBoxInner>
                ))}
              </ElearningProcessBox>
            </Col>
          </Row>
        </Container>
      </ElearningProcessInner>
    </ElearningProcessWrapper>
  );
}
VoiceProcess.propTypes = {
  textStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  descStyle: PropTypes.object,
};

VoiceProcess.defaultProps = {
  headingStyle: {
    as: "h4",
    color: "#fff",
    fontSize: "22px",
    fontweight: 700,
  },
  descStyle: {
    color: "#fff",
  },
};
